import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","xl":"6","md":"8","offset-md":"2","offset-xl":"3"}},[(_vm.loading && _vm.blogEntries.length === 0)?_c(VProgressCircular,{staticClass:"mx-auto d-block my-16",attrs:{"size":100,"width":5,"color":"lime","indeterminate":""}}):_vm._l((_vm.blogEntries),function(blogEntry){return _c(VHover,{key:blogEntry.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mb-4",class:{ 'hover-opacity': hover },attrs:{"elevation":hover ? 4 : 1,"to":{
            name: _vm.$constants.ROUTE_NAMES.BLOG_SHOW,
            params: { slug: blogEntry.slug },
          }}},[_c(VCardTitle,{staticClass:"text-h4 break-word"},[_vm._v(_vm._s(blogEntry.title))]),_c(VCardSubtitle,{staticClass:"text-uppercase caption"},[_c('span',{staticClass:"lime--text text--darken-2"},[_vm._v(_vm._s(_vm._f("date")(blogEntry.timestamp)))]),_c('span',[_vm._v(" • "+_vm._s(blogEntry.readMinutes)+" min read")])]),_c(VCardText,[_c('p',{staticClass:"text--primary"},[_vm._v(_vm._s(blogEntry.intro))])])],1)]}}],null,true)})}),_c('div',{staticClass:"text-center mt-4"},[_c('back-button')],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }