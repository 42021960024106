

















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import "firebaseui/dist/firebaseui.css";
import { Action, Getter } from "vuex-class";
import BackButton from "@/components/BackButton.vue";
import { BlogEntry, AppData } from "@/types/state";

@Component({
  components: { BackButton },
})
export default class BlogIndex extends Vue {
  @Getter("loading") loading!: boolean;
  @Getter("appData") appData!: AppData;
  @Getter("blogEntries") blogEntries!: Array<BlogEntry>;
  @Action("loadBlogState") loadBlogState!: () => Promise<void>;
  @Action("setPageTitle") setPageTitle!: (title: string) => Promise<void>;
  @Action("setTitle") setTitle!: (title: string) => void;

  mounted(): void {
    this.loadBlogState();
    this.setTitle("");
    this.setPageTitle(`Blog`);
  }
}
